<template>
	<!-- 记事信息 -->
	<div class="mod-config">
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
			<div class="title">{{ title }}</div>
			<div class="line"></div>
			<el-row
				style="margin-top: -10px;margin-left: -20px;line-height:30px;width: 103%;">
				<el-row style="margin-top: 10px;">
					<el-col :span="2">
						<div class="search_title">标的物</div>
					</el-col>
					<el-col :span="22" style="display: flex;flex-wrap: wrap;">
						<span v-for="(subject, index) in subjectList" style="margin-right: 20px;">
							<span class="search_bn_border" v-if="subjectNo === subject.displayValue"
								@click="setSubject(subject)">
								{{ subject.displayName }}
							</span>
							<span class="search_bn" v-else-if="subjectNo !== subject.displayValue"
								@click="setSubject(subject)">
								{{ subject.displayName }}
							</span>
						</span>
					</el-col>
				</el-row>
				<el-row style="margin-top: 17px;">
					<el-col :span="2">
						<div class="search_title">专业</div>
					</el-col>
					<el-col :span="2" v-for="(maj, index) in majorList">
						<span class="search_bn_border" v-if="major === maj.displayValue" @click="setMajor(maj)">
							{{ maj.displayName }}
						</span>
						<span class="search_bn" v-else-if="major !== maj.displayValue" @click="setMajor(maj)">
							{{ maj.displayName }}
						</span>
					</el-col>
				</el-row>
				<div class="line"></div>
				<el-row style="background-color: rgb(243, 247, 247);margin-top: -10px;height: 45px;width: 103%;">
					<el-col :span="pageSpan" v-if="showAdd" style="margin-left:15px;margin-top:5px">
						<el-button v-preventReClick class="btn" type="primary" size="small"
							@click="addHandle()">添加</el-button>
					</el-col>
				</el-row>
				<div class="line" style="margin-top: -3px;"></div>
				<el-form ref="form" :model="form" inline label-width="80px" label-position="left">
					<el-row
						style="background-color: rgb(243, 247, 247);margin-top: -10px;margin-left: 0px;height: 45px;width: 103%;">
						<el-col :span="18" style="margin-left:20px">
							<!-- <el-form-item label="标的物" class="selFormItem">
							<el-select class="selSubjectInput" v-model="subjectNo" placeholder="请选择" clearable >
								<el-option v-for="item in $store.state.common.navBarSubjectList" :key="item.displayValue" :label="item.displayName"
									:value="item.displayValue">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="专业" class="selFormItem">
							<el-select class="selMajorInput" v-model="major" placeholder="请选择" clearable>
								<el-option v-for="item in majorList" :key="item.displayValue" :label="item.displayName"
									:value="item.displayValue">
								</el-option>
							</el-select>
						</el-form-item> -->
							<el-form-item label="登记时间" class="selFormItem" style="margin-top:2px">
								<el-date-picker class="selDateInput" v-model="recContentTime" value-format="yyyy-MM-dd"
									type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期">
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :span="4" class="selBtnGroup" style="margin-top:5px">
							<el-button v-preventReClick class="btn" type="primary" size="small" @click="selGetDataList()"
								v-if="showSearch">查询</el-button>
							<el-button v-preventReClick class="rebtn" size="small" @click="resetFormData()">重置</el-button>
						</el-col>
					</el-row>
				</el-form>
			</el-row>
		</el-card>
		<div>
			<el-card style="margin-top: -25px; height: 450px;" body-style="height:410px; padding: 0;">
				<el-row style="height: 100%; margin-bottom: 10px;">
					<el-col :span="24" style="height: 100%;">
						<div class="box">
							<el-table height="390" :row-class-name="tableRowClassName" class="dataListTable" border
								:data="dataList" header-align="center" style="width: 100%;margin-bottom: 20px;"
								header-cell-class-name="stepPointTHeader" :cell-style="{ padding: '0px' }">
								<el-table-column type="index" label="NO." width="60" align="center">
									<template slot-scope="scope">{{ (pageIndex2 - 1) * limit + scope.$index + 1
									}}</template>
								</el-table-column>
								<el-table-column prop="photoPathUri" label="照片 " width="200" align="center">
									<template slot-scope="scope">
										<el-image :src="scope.row.photoPathUri" style="height: 100px;" fit="contain" />
									</template>
								</el-table-column>
								<!-- <el-table-column prop="subjectName" label="标的物" width="100" align="center">
								</el-table-column>
								<el-table-column prop="major" label="专业" width="80" align="center">
								</el-table-column> -->
								<el-table-column prop="photoContent" label="描述 " min-width="350" align="center">
								</el-table-column>
								<el-table-column prop="registerTime" label="登记时间" width="100" align="center">
								</el-table-column>
								<el-table-column label="操作" width="200" fixed="right" v-if="showDelete || showEdit"
									align="center">
									<template slot-scope="scope">
										<el-button v-preventReClick type="text" size="small"
											@click="updateHandle(scope.row.recNo)" v-if="showEdit">修改</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="deleteHandle(scope.row.recNo)" v-if="showDelete">删除</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<el-pagination class="my-pagination" @size-change="sizeChangeHandle"
							@current-change="currentChangeHandle" :current-page="page" :page-sizes="[10, 20, 50, 100]"
							:page-size="limit" :total="totalCount" layout="total, sizes, prev, pager, next, jumper">
						</el-pagination>
					</el-col>
				</el-row>
			</el-card>
		</div>
		<add-handle v-if="addVisible" ref="addHandle" @refreshDataList="getDataList()"></add-handle>
		<update-handle v-if="updateVisible" ref="updateHandle" @refreshDataList="getDataList()"></update-handle>
	</div>
</template>
<script>
import AddHandle from './qualityProgressPhoto-add'
import UpdateHandle from './qualityProgressPhoto-update.vue'
import $common from "@/utils/common.js"
export default {
	data() {
		return {
			showSearch: false,
			showAdd: false,
			showEdit: false,
			showDelete: false,
			pageSpan: 12,
			subjectNo: '',
			form: {},
			subjectList: [],
			majorList: [],
			dataList: [],
			recContentTime: [],
			// subjectNo:"",
			limit: 20,
			page: 1,
			totalCount: 0,
			// addOrUpdateVisible: false,
			addVisible: false,
			updateVisible: false,
			photoType: '',
			major: '',
			pageIndex2: 1,
			title: ''
		};
	},
	components: {
		AddHandle,
		UpdateHandle
	},
	mounted() {
		this.photoType = this.$route.query.type;
		if (this.photoType === '1') {
			this.title = '质量照片'
		} else if (this.photoType === '2') {
			this.title = '进度照片'
		}
		this.showSearch = $common.isAuth('qualityProgressPhoto:search:' + this.photoType);
		this.showAdd = $common.isAuth('qualityProgressPhoto:add:' + this.photoType);
		this.showEdit = $common.isAuth('qualityProgressPhoto:edit:' + this.photoType);
		this.showDelete = $common.isAuth('qualityProgressPhoto:delete:' + this.photoType);
		this.getSubjectDropDown();
		this.getMajorList();
		if (!this.showAdd) {
			this.pageSpan = 24;
		}
		// this.getDataList();
		this.pageIndex2 = 1;
	},
	filters: {
		//文字数超出时，超出部分使用...
		ellipsis(value) {
			if (!value) return ''
			if (value.length > 35) {
				return value.slice(0, 35) + '...'
			}
			return value
		}
	},
	methods: {
		selGetDataList() {
			this.page = 1;
			this.getDataList();
		},
		resetFormData() {
			// this.subjectNo = "";
			this.recContentTime = [];
			// this.major = '';
		},
		currentChangeHandle(val) {
			this.page = val
			this.getDataList()
		},
		// 每页数
		sizeChangeHandle(val) {
			this.limit = val;
			this.pageIndex = 1;
			this.getDataList();
		},
		siteContentViewHeight() {
			var height = this.$store.state.common.documentClientHeight - 260;
			return { height: height + 'px' }
		},
		getDataList() {
			if (this.showSearch) {
				this.$http({
					url: this.$store.state.httpUrl + "/business/qualityprogressphotoinfo/list",
					method: "get",
					params: {
						limit: this.limit,
						page: this.page,
						subjectNo: this.subjectNo,
						photoType: this.photoType,
						beginTime: this.recContentTime[0] ? this.recContentTime[0] : '',
						endTime: this.recContentTime[1] ? this.recContentTime[1] : '',
						workNo: $common.getItem("workNo"),
						major: this.major
					},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.dataList = data.body.records;
						this.totalCount = data.body.totalCount;
						this.pageIndex2 = this.page
					}
				});
			}
		},
		getSubjectDropDown() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.subjectList = data.body;
					if (this.subjectList != undefined && this.subjectList.length > 0) {
						this.subjectNo = this.subjectList[0].displayValue
						if (this.showSearch) {
							this.getDataList();
						}
					}
				}
			});
		},
		getMajorList() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getMajorByWorkNo/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.majorList = data.body;
					if (this.majorList != undefined && this.majorList.length > 0) {
						this.major = this.majorList[0].displayValue
						if (this.showSearch) {
							this.getDataList();
						}
					}
				}
			});
		},
		setMajor(major) {
			this.major = major.displayValue
			this.getDataList()
		},
		setSubject(subject) {
			this.subjectNo = subject.displayValue
			this.getDataList()
		},
		addHandle() {
			this.addVisible = true
			this.$nextTick(() => {
				this.$refs.addHandle.init(this.photoType, this.subjectNo, this.major)
			})
		},
		updateHandle(id) {
			this.updateVisible = true
			this.$nextTick(() => {
				this.$refs.updateHandle.init(id, this.photoType)
			})
		},
		deleteHandle(id) {
			this.$confirm(`您确定要删除选择的数据么?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/qualityprogressphotoinfo/delete/" + id,
					method: 'post',
					data: {}
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				})
			})
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
	}
};
</script>
<style lang="scss" scoped="scoped">
.noteTitle {
	font-weight: bold;
	color: #00428e;
	border-bottom: 1px solid #00428e;
	padding-bottom: 10px;
	margin-top: 20px;
}

.noteContent {
	color: #727580;
	margin-top: 10px;
	line-height: 25px;
}

.time {
	font-size: 13px;
	color: #999;
}

.bottom {
	margin-top: 13px;
	line-height: 12px;
}

.button {
	padding: 0;
	float: right;
}

.image {
	width: 100%;
	display: block;
	height: 100px;
	background-size: auto 100% !important
}

.clearfix:before,
.clearfix:after {
	display: table;
	content: "";
}

.clearfix:after {
	clear: both
}

.my-pagination {
	margin-right: 20px !important;
}

::v-deep(.el-table th.el-table__cell) {
	// background-color: #d3e2f4 !important;
	font-size: 15px;
	padding: 2px 0;
}

.box {
	height: 100%;
	overflow: auto;
}

::v-deep(.selSubjectInput .el-input__inner) {
	width: 120px !important;
	height: 30px !important;
}

::v-deep(.selMajorInput .el-input__inner) {
	width: 110px !important;
	height: 30px !important;
}

.selDateInput {
	width: 280px !important;
	margin-top: 2px;
	height: 32px;

	::v-deep .el-input__inner {
		height: 33px;
	}
}

::v-deep(.selDateInput .el-range-separator) {
	width: 40px !important;
	margin-top: -8px;
}

::v-deep(.selFormItem .el-form-item__label) {
	font-size: smaller;
}
</style>
