<template>
    <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="visible" width="950px">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" :inline="true" label-width="100px" class="border-form">
            <el-row class="set_border">
                <el-col :span="8" class="col-set_border">
                    <el-form-item prop="registerTime" label="登记时间" class="item">
                        <el-date-picker v-model="dataForm.registerTime" type="date" value-format="yyyy-MM-dd"
                            placeholder="选择日期" style="width: 150px;">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item prop="subjectNo" label="标的物" class="item">
                        <template slot-scope="scope">
                            <el-select id="subjectNo" v-model="dataForm.subjectNo" placeholder="请选择" style="width: 150px;">
                                <el-option v-for="item in subjectList" :key="item.displayValue" :label="item.projectNo"
                                    :value="item.displayValue">
                                </el-option>
                            </el-select>
                        </template>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item prop="major" label="专业" class="item1">
                        <template slot-scope="scope">
                            <el-select v-model="dataForm.major" placeholder="请选择" style="width: 150px;">
                                <el-option v-for="item in majorList" :key="item.displayValue" :label="item.displayName"
                                    :value="item.displayValue">
                                </el-option>
                            </el-select>
                        </template>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="set_border">
                <el-col :span="24">
                    <el-form-item label="描述" class="item">
                        <el-input class="selItemInput descAreaLg" type="textarea" rows="2" v-model="dataForm.photoContent"
                            placeholder="请填写"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row style="margin-top: 15px;">
                <el-col :span="24">
                    <el-form-item label="" style="margin-left: 380px;">
                        <div class="addImgBox" v-if="dataForm.photoFilePath">
                            <el-image style="width: 100%; height: 100%" :src="dataForm.photoPathUri" fit="cover"></el-image>
                            <div class="delBox">
                                <i class="el-icon-delete" @click="delImg()"></i>
                            </div>
                        </div>
                        <el-upload v-if="!dataForm.photoFilePath" class="addImgBox" ref="uploadImg" :action="action"
                            :http-request="uploadImg" accept="image/jpeg,image/png,image/jpg">
                            <el-image style="width: 100%; height: 100%" :src="addImg" fit="cover">
                            </el-image>
                        </el-upload>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <!-- <span style="margin-left:50px;color: red;font-weight: 700;">* 照片尺寸 598px × 449px</span> -->
                </el-col>
            </el-row>
            <el-row class="formBtnGroup">
                <el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">保存</el-button>
                <el-button v-preventReClick class="rebtn" size="small" @click="visible = false">取消</el-button>
            </el-row>
        </el-form>
    </el-dialog>
</template>

<script>
import addImg from "@/assets/images/addImg.png"
import $common from "@/utils/common.js"
export default {
    name: "qualitySituationList-add-or-update",
    data() {
        return {
            title: '',
            addImg: addImg,
            id: "",
            visible: true,
            action: "",
            form: {},
            subjectList: [],
            majorList: [],
            categoryList: [],
            subjectNo: $common.getItem("subjectNo"),
            dataForm: {
                workNo: $common.getItem("workNo"),
                recNo: "",
                photoType: "",
                registerTime: "",
                registerMan: "",
                photoContent: '',
                photoFilePathList: [],
                major: ''
            },
            dataRule: {
                registerTime: [
                    { required: true, message: "登记时间不能为空！", trigger: "blur" }
                ],
                subjectNo: [
                    { required: true, message: "标的物不能为空！", trigger: "blur" }
                ],
                major: [
                    { required: true, message: "专业不能为空！", trigger: "blur" }
                ]
            }
        };
    },
    components: {

    },
    activated() {

    },
    mounted() {
        this.getSubjectDropDown();
        this.getMajorList()
    },
    methods: {
        init(id, photoType) {
            this.dataForm = {
                workNo: $common.getItem("workNo"),
                subjectNo: '',
                major: '',
                recNo: "",
                photoSort: '',
                photoType: "",
                originalFileName: '',
                photoFilePath: '',
                thumbnailPath: '',
                registerTime: "",
                registerMan: "",
                photoContent: '',
            };
            this.dataForm.recNo = id;
            this.dataForm.photoType = photoType;
            this.dataForm.registerMan = $common.getItem("userName");
            this.getDetail();
            this.title = '修改' + this.getPhotoTypeName();
            this.$nextTick(() => {
                this.visible = true;
            })
        },
        getPhotoTypeName() {
            if (this.dataForm.photoType === '1') {
                return '质量照片';
            } else if (this.dataForm.photoType === '2') {
                return '进度照片';
            }
        },
        getDetail() {
            this.$http({
                url: this.$store.state.httpUrl + "/business/qualityprogressphotoinfo/info/" + this.dataForm.recNo,
                method: "get",
                params: {},
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.dataForm = data.body;
                    if (!this.dataForm.registerMan) {
                        this.dataForm.registerMan = $common.getItem("userName");
                    }
                }
            });
        },
        getSubjectDropDown() {
            this.$http({
                url: this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
                method: "get",
                params: {},
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.subjectList = data.body;
                }
            });
        },
        getMajorList() {
            this.$http({
                url: this.$store.state.httpUrl + "/common/getMajorByWorkNo/" + $common.getItem("workNo"),
                method: "get",
                params: {},
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.majorList = data.body;
                }
            });
        },
        //图片
        delImg() {
            this.dataForm.photoFilePath = '';
            this.dataForm.thumbnailPath = '';
            this.dataForm.originalFileName = '';
            this.dataForm.photoPathUri = '';
        },
        uploadImg(param) {
            const formData = new FormData();
            formData.append('file', param.file);
            this.$refs.uploadImg.clearFiles();
            this.$http.post(
                this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/94/0",
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' }
                },
            ).then(res => {
                res = res.data
                if (res.resultCode == 200) {
                    this.dataForm.photoFilePath = res.body.fileSavePath;
                    this.dataForm.thumbnailPath = res.body.thumbnailPath;
                    this.dataForm.originalFileName = res.body.fileName;
                    this.dataForm.photoPathUri = res.body.fileUri;
                } else {
                    this.$message.info('文件上传失败')
                }
            })
        },
        dataFormSubmit() {
            this.$refs["dataForm"].validate(valid => {
                if (valid) {
                    this.$http({
                        url: this.$store.state.httpUrl + "/business/qualityprogressphotoinfo/update",
                        method: "post",
                        data: this.$http.adornData(this.dataForm)
                    }).then(({ data }) => {
                        if (data && data.resultCode === 200) {
                            this.$message({
                                message: "操作成功",
                                type: "success",
                                duration: 1500,
                                onClose: () => {
                                    this.visible = false;
                                    this.$emit("refreshDataList");
                                }
                            });
                        } else {
                            this.$message.error(data.msg);
                        }
                    });
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped="scoped">
.selItemInput.descAreaLg {
    width: 742px !important;
}

.addImgBox {
    width: 136px;
    height: 136px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

.item::v-deep .el-form-item__label {
    color: #00428E !important;
    margin-right: 10px !important;
    font-weight: bold !important;
    background-color: #E1F1FF;
    white-space: nowrap !important;
    border-right: solid #dadada 1px;
}

.item1::v-deep .el-form-item__label {
    color: #00428E !important;
    margin-right: 10px !important;
    font-weight: bold !important;
    background-color: #E1F1FF;
    white-space: nowrap !important;
    border-right: solid #dadada 1px;
    border-left: solid #dadada 1px;
}

::v-deep .el-form-item {
    display: flex !important;
    align-items: stretch !important;
    margin-bottom: 0px !important;
}

.border-form .el-row.set_border {
    border-bottom: solid #dadada 1px;
    border-left: solid #dadada 1px;
    border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
    border-top: solid #dadada 1px;
}

.border-form .el-row.set_border .col-set_border {
    border-right: solid #dadada 1px;
}
</style>
